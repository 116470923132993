import { LazycatAccountCenter } from "@lazycatcloud/public-account"
import axios from "axios"
// playgroundUserApi
const pgUserApi = axios.create({ baseURL: "https://playground.lazycat.cloud/api/user" })

pgUserApi.interceptors.request.use(
  async cfg => {
    if (!cfg?.headers.has("X-User-Token")) {
      cfg.headers["X-User-Token"] = await LazycatAccountCenter.getToken()
    }
    return cfg
  },
  err => console.error("axios request got error", err)
)
interface UserInfoResponse {
  /**
   * 总获赞数，包括评论和攻略
   */
  receiveThumbs: number
  /**
   * 关注数
   */
  follows: number
  /**
   * 粉丝
   */
  fans: number
}

export interface PrivacySettingRequest {
  /**
   *
   * 隐藏我的关注列表
   */
  hideFollows: boolean
  /**
   *
   * 隐藏我的粉丝列表
   */
  hideFans: boolean
  /**
   *
   * 隐藏我的赞
   */
  hideThumbs: boolean
  /**
   *
   * 只允许我评论的人@我
   */
  onlyCommentedAtMe: boolean
  /**
   *
   * 只允许我关注的人评论我
   */
  onlyFollowedCommentMe: boolean
}
export interface PrivacySettingResponse {
  id: number
  createdAt: string
  updatedAt: string
  /**
   *
   * 用户的id
   */
  uid: number
  /**
   *
   * 用户信息
   */
  user: UserInfoResponse
  /**
   *
   * 隐藏我的关注列表
   */
  hideFollows: boolean
  /**
   *
   * 隐藏我的粉丝列表
   */
  hideFans: boolean
  /**
   *
   * 隐藏我的赞
   */
  hideThumbs: boolean
  /**
   *
   * 只允许我评论的人@我
   */
  onlyCommentedAtMe: boolean
  /**
   *
   * 只允许我关注的人评论我
   */
  onlyFollowedCommentMe: boolean
}

export async function getUserInfo(uid: number | string) {
  return (await pgUserApi.get<UserInfoResponse>(`/info/${uid}`)).data
}

export async function getPrivacy() {
  return (await pgUserApi.get<PrivacySettingResponse>(`/privacy`)).data
}

export async function setPrivacy(req: Partial<PrivacySettingRequest>) {
  return await pgUserApi.post(`/privacy`, req)
}
